import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';
import { Carousel, Col, Row } from 'antd';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const WhatPage = () => {
	const [ titleAssortiments, setTitleAssortiments ] = useState('');
	const [ imgAssortiments, setImgAssortiments ] = useState('');
	const [ assortiments, setAssortiments ] = useState([]);

	const data = useStaticQuery(graphql`
	    query {
	      imageWhat: file(relativePath: { eq: "what.jpg" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4WpcBEAACcAHOaL', { lang : 'fr-ch' })
		).then(response => {
			setTitleAssortiments(response.data.titre.length ? response.data.titre[0].text : '');
			setImgAssortiments(response.data.legume_image.url ? response.data.legume_image.url : '');
			setAssortiments(response.data.body);
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="Quoi ?" />

			<div className="hero-image">
				<Img fluid={data.imageWhat.childImageSharp.fluid} />
			</div>

			<div>
				<div className="page-section">
					<div className="section-title">
						<h2>
							{titleAssortiments}
							<img src={imgAssortiments} alt={titleAssortiments} />
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-list section-list-small">
						<Row gutter={[32, 32]} justify="space-between">
							<Col xs={24} md={12}>
								{assortiments.slice(0, Math.floor(assortiments.length / 2)).map((a, k) => (
									<div key={k} className="card">
										<Row key={k} gutter={32} align="middle">
											<Col xs={24} lg={24} xl={16}>
												{a.primary.title.length ? <h3>{a.primary.title[0].text}</h3> : null}
												{a.primary.content.length ? <div className="content" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(a.primary.content) }} /> : null}
											</Col>
											<Col xs={24} lg={24} xl={8}>
												<Carousel className="preview" autoplay={true} effect="fade">
													{a.items.map((img, i) => (
														<img key={i} src={img.image.url ? img.image.url : ''} alt={a.primary.title.length ? <h3>{a.primary.title[0].text}</h3> : ''} />
													))}
												</Carousel>
											</Col>
										</Row>
									</div>
								))}
							</Col>
							<Col xs={24} md={12}>
								{assortiments.slice(Math.floor(assortiments.length / 2), assortiments.length).map((a, k) => (
									<div key={k} className="card">
										<Row key={k} gutter={32} align="middle">
											<Col xs={24} lg={24} xl={16}>
												{a.primary.title.length ? <h3>{a.primary.title[0].text}</h3> : null}
												{a.primary.content.length ? <div className="content" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(a.primary.content) }} /> : null}
											</Col>
											<Col xs={24} lg={24} xl={8}>
												<Carousel className="preview" autoplay={true} effect="fade">
													{a.items.map((img, i) => (
														<img key={i} src={img.image.url ? img.image.url : ''} alt={a.primary.title.length ? <h3>{a.primary.title[0].text}</h3> : ''} />
													))}
												</Carousel>
											</Col>
										</Row>
									</div>
								))}
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default withTrans(WhatPage);
